$main-color:    rgba(35,74,29,1);
$main-color-2:  rgba(198,221,205,1);

$grey-light:    rgba(225,225,225,1);
$grey:          rgba(175,175,175,1);
$grey-dark:     rgba(125,125,125,1);
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

header{
  padding-top: 55px;

  h1{
    font-family: 'Great Vibes', cursive;
    font-size: 2.5rem;
    font-weight: 500;
    padding: 1.5rem 0 1rem;
    background: $main-color-2;
    color: white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .25);

    span{
      display: block;
      font-size: 0.6em;

      @include media-breakpoint-up(sm) {
        font-size: 0.8em;
      }
      @include media-breakpoint-up(md) {
        display: inline;
        font-size: 1em;
      }
    }
  }
  #raute{
    display: none;
    position: absolute;
    top: -100px;
    left: 50%;
    background: $main-color;
    width: 150px;
    height: 150px;
    box-shadow: 0 0 10px rgba(0,0,0,.5);
    transform: translateX(-75px) rotateZ(45deg);

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    > div{
        margin-top: 75px;
        margin-left: 75px;
        transform: rotateZ(-45deg);
        color: white;
        font-size: 0.8rem
    }
  }
  .navbar{
    background: white;

    .nav-link{
      padding: .5rem .75rem;

      @include media-breakpoint-up(lg) {
        padding: .5rem 1rem;
      }
    }

    .newInfo.nav-item{
      svg{
        color: #e3c062;

        &:hover{
          transform: scale(1.5);
          color: $main-color-2;
        }
      }
      &::before{
        content: "UPDATE";
        position: absolute;
        font-size: .5rem;
        background: #234a1d;
        color: white;
        font-weight: bold;
        padding: 0 0.125rem;
      }
    }

    svg{
      color: $main-color;
      transition: 0.35s ease all;
      transform-origin: center;
      cursor: pointer;

      &:hover{
        transform: scale(1.5);
        color: $main-color-2;
      }

      &.nonfeather{
        transition: 0.35s ease all;
        path{
          fill: $main-color;
          transition: 0.35s ease all;
        }
        &:hover{
          path{
            fill: $main-color-2;
          }
        }
      }
    }
  }
}
.well{
  border: 1px solid $main-color-2;
  padding: 1rem;

    h1{
      margin-bottom: 2rem;
      font-family: 'Great Vibes', cursive;
      font-size: 2.5rem;
      font-weight: 500;
      padding: 1.5rem 0 1rem;
      background: $main-color-2;
      color: white;
      box-shadow: 0 5px 10px rgba(0, 0, 0, .25);

      span{
        display: block;
        font-size: 0.6em;

        @include media-breakpoint-up(sm) {
          font-size: 0.8em;
        }
        @include media-breakpoint-up(md) {
          display: inline;
          font-size: 1em;
        }
      }
  }
}
