$main-color:    rgba(35,74,29,1);
$main-color-2:  rgba(198,221,205,1);

$grey-light:    rgba(225,225,225,1);
$grey:          rgba(175,175,175,1);
$grey-dark:     rgba(125,125,125,1);
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

figure.banner{
  position: relative;

  h2{
    position: absolute;
    z-index: 1;
    bottom: 0;
    width: 100%;
    padding: 1rem 0 .5rem;
    margin-bottom: 1rem;
    background: rgba(255,255,255,.75);
    color: $main-color;
    font-family: 'Great Vibes', cursive;
  }
}

ul.abc{
  padding: 0;
  list-style: none;

  li{
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    margin-bottom: 4rem;
    background: $grey-light;
    background: rgb(255,255,255);
    background: -moz-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 0%, rgba(225,225,225,1) 100%);
    background: -webkit-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 0%,rgba(225,225,225,1) 100%);
    background: radial-gradient(ellipse at center,  rgba(255,255,255,1) 0%,rgba(225,225,225,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e1e1e1',GradientType=1 );

    border-radius: 25px;
    transition: 1s ease all;

    @include media-breakpoint-up(lg) {
      margin-bottom: 2rem;
      border-radius: 35px;
    }

    &:first-child{
      margin-top: 4rem;

      @include media-breakpoint-up(lg) {
        margin-top: 2rem;
      }
    }
    &:last-child{
      margin-bottom: 0;
    }

    &:hover{
      background: $grey;
      box-shadow: 0 10px 10px rgba(0,0,0,.25);
      transform: scale(1.025);

      div.info{ color: white;}
    }

    > div:last-child{
      flex: 1;
      padding: 0 1rem 1rem;
      width: auto;
      text-align: left;
    }
    div.name{
      color: $main-color;
      font-size: 1.75rem;
      font-family: 'Great Vibes', cursive;
    }
    div.key{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      margin: -4rem 0 1rem;
      border-radius: 50px;
      border: 1px solid $grey-light;
      background: white;
      color: $grey-light;
      text-align: center;
      font-size: 3rem;
      font-family: 'Great Vibes', cursive;
      text-transform: uppercase;
      transition: 1s ease all;
      color: #e3c062;
      text-shadow: 5px 5px 10px rgba(0,0,0,.25);

      @include media-breakpoint-up(lg) {
        margin: 0 0 0 -3rem;
      }
    }
    div.name{
      transition: 1s ease all;
    }
    div.info{
      color: $grey-dark;
      transition: 1s ease all;

      a{
        color: $main-color;
        text-decoration: none;
      }
    }
  }
}
.card-deck-fotos .card{
  transition: 0.5s ease all;

  img{
    filter: grayscale(25%);
  }
  h5{
    opacity: 1;
    padding: 0.5rem 0 .25rem;
    color: $main-color;
    font-family: 'Great Vibes', cursive;
    transition: 0.5s ease all;
    background: rgba(255,255,255,.75);
  }
  &:hover{
    transform: scale(1.025);

    img{
      filter: grayscale(0%);

    }
  }
  &{
    display: flex;
    justify-content: center;

    &:after{
      content: "Fotos anschauen";
      position: absolute;
      bottom: 1rem;
      background: $main-color;
      color: white;
      text-align: center;
      left: 1rem;
      right: 1rem;
      opacity: 0;
      transition: .5s ease all;
    }

    &:hover{
      &:after{
        opacity: 1;
      }
    }
  }
  &.disabled{
    &:after{
      content: "Bald verfügbar";
    }

    &:hover{
      img{
        filter: grayscale(100%);
      }
    }
  }
}
.border-none{
  border: none;
}

.card-deck-content{
  .card-img-top{
    padding: 1rem;
    margin: 0 auto;
    border-radius: 100px;
    max-width: 150px;
  }
  .card{
    border-color: $grey-light;
    border-radius: 0;
  }
  .card:hover{
    background: lighten($grey-light, 7.5%);
    transform: scale(1.015);
    transition: 0.35s ease all;
  }

  h5.card-title{
    font-family: 'Great Vibes', cursive;
  }
  ul{
    font-size: 0.7rem;
    color: $grey-dark;

    li.d-flex.d-none{
      display: none !important;
    }

    svg{
      width: 20px;
      height: auto;
      color: $main-color;
    }
    a{
      color: $grey-dark;
      text-decoration: none;
      transition: .35s ease all;
      word-break: break-all;

      &:hover{
        color: $main-color;
      }
    }
  }
}
.carousel-control-prev-icon,
.carousel-control-next-icon{

}
.carousel-indicators{
  display: none;
}
.abc li.newInfo{
  background: #e3c062 !important;
  position: relative;
}
.abc li.newInfo::before{
  content: "Update";
  background: #234a1d;
  position: absolute;
  right: 0;
  top: 0;
  color: white;
  padding: 0.25rem 2rem;
  font-size: .75rem;
  transform: rotateZ(15deg);
}


.loca{
    h3{
        color: $main-color;
        font-family: 'Great Vibes', cursive;
    }
}
.card-info.card-info-later{
    padding-bottom: 0;
}
.wrap-card{
  margin-bottom: 1rem;

  .card{
    border: 1px solid $main-color-2;
    border-radius: 0;

    img{
      border-radius: 0;
    }
  }
  .logisticsInfo{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0;
      margin: 0;
      border: 1px solid $main-color-2;
      border-top: 0;

      .tab-pane{
          text-align: left;
      }

      .nav-pills{
        background: $main-color-2;

        .nav-item{
          .nav-link{ color: $main-color; }
          .nav-link.active{
            background: white;
            border-radius: 0;
            color: $main-color;
          }
        }
      }

      .subTabs{
        padding: 1rem 0;
        font-size: .9rem;

        :last-child{
          margin-bottom: 0;
        }

        a{
          color: $main-color;
          text-decoration: underline;
        }
      }

      &.items3{
          .nav{
              div:nth-child(2){
                  display: none;
              }
          }
          .infoPrice{
              display: none;
          }
      }

      .infoPrice{
          .price{
              svg{
                  color: $main-color-2;
                  margin-right: -5px;
              }
              &.price1{
                  svg:nth-child(-n+1){
                      color: $main-color;
                  }
              }
              &.price2{
                  svg:nth-child(-n+2){
                      color: $main-color;
                  }
              }
              &.price3{
                  svg:nth-child(-n+3){
                      color: $main-color;
                  }
              }
              &.price4{
                  svg:nth-child(-n+4){
                      color: $main-color;
                  }
              }
              &.price5{
                  svg:nth-child(-n+5){
                      color: $main-color;
                  }
              }
          }
      }
  }
}

.card-info{
    .card-title{
        h5{
            position: relative;
            overflow: hidden;
            padding: 1rem;
            box-shadow: 0 10px 15px rgba(0, 0, 0, .5);
            background: rgba(255,255,255,.65);
            color: $main-color;
            font-size: 2rem;
            font-family: 'Great Vibes', cursive;
            text-align: center;

            span{
                position: absolute;
                top: 15px;
                left: -75px;
                display: none;
                width: 300px;
                padding: .5rem 1rem;
                background: $main-color;
                color: $main-color-2;
                font-size: 1rem;
                text-transform: capitalize;
                text-align: center;
                transform: rotateZ(-45deg);

                @include media-breakpoint-up(md) {
                    display: block;
                    left: -125px;
                }
                @include media-breakpoint-up(lg) {
                    display: block;
                    left: -75px;
                }
            }
        }
    }
}
.countdown,
h4{
    color: $main-color;
    font-family: 'Great Vibes', cursive;
}
.more-info{
  color: $main-color;
  background: $main-color-2;
  padding: .25rem .5rem;

  ul{
    li{
      margin-right: .5rem;
      &:last-child{
        margin-right: 0;
      }

      a{
        color: $main-color;
        &:hover{
          color: black;
        }
      }
    }
  }
}
.hori-timeline{
    .events {
        border-top: 1px solid $main-color-2;

        h5{
//            margin-top: 50px;
            color: $main-color;
            font-size: 1.25rem;
            font-family: 'Great Vibes', cursive;
            transition: .25s ease all;
        }

        svg.leaf{
            margin: .5rem 0;
            fill: $main-color-2;
        }

        .event-list {
            display: block;
            position: relative;
            text-align: center;
            padding-top: 35px;
            margin-right: 0;

            &:after{
                content: "";
                position: absolute;
                height: 36px;
                border-right: 2px dashed $main-color-2;
                top: 0;
                transition: 0.25s ease all;
            }

            .event-date {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 75px;
                height: 75px;
                margin: 0 auto;
                border-radius: 50px;
                border: 2px solid $main-color;
                padding: 2px 4px;
                overflow: hidden;
                background: $main-color-2;
                transition: 0.25s ease all;

                svg:not(.leaf){
                    fill: white;
                    transition: 0.25s ease fill;
                    width: 100%;
                    height: auto;
                }
                figcapture{
                    display: none;
                }
            }
            &:hover{
                .event-date {
                    margin-top: -5px;
                    margin-bottom: 5px;
                    box-shadow: 0 10px 10px rgba(0,0,0,.15);
                    transform: scale(1.05);

                    svg:not(.leaf){
                        fill: $main-color;
                    }
                }
                h5{
                    font-size: 1.5rem;
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .hori-timeline{
        .events{
            display: flex;

            svg.leaf{
                margin: 1rem 0;
            }

            .event-list {
                display: inline-block;
                width: calc(100% / 6);
                padding-top: 40px;

                &:before{
                    content: "";
                    position: absolute;
                    height: 5px;
                    top: 0;
                    z-index: 10;
                    width: 20px;
                    transform: rotateZ(-45deg);
                    left: 50%;
                    margin-left: -8px;
                    border-top: 2px solid $main-color;
                    border-bottom: 2px solid $main-color-2;
                    transition: .25s ease all;
                }

                .event-date {
                    top: 35px;
                }

                &:hover{
                    &:before{
                        content: "";
                        transform: rotateZ(45deg);
                    }
                    &:after{
                        content: "";
                        border-right: 2px dashed $main-color;
                    }
                }
            }
        }
    }
}

.newsflash{
  li{
    color: #aaa;
    margin-bottom: 1rem;

    &:hover{
      color: #333;
    }
  }
}
