$main-color:    rgba(35,74,29,1);
$main-color-2:  rgba(198,221,205,1);

$grey-light:    rgba(225,225,225,1);
$grey:          rgba(175,175,175,1);
$grey-dark:     rgba(125,125,125,1);

#nav-footer{
  .nav-item{
    border-right: 1px solid $grey-light;

    &:last-child{
      border-right: none;
    }

    .nav-link{
      color: $grey-dark;
      transition: 0.35s ease all;
      transform-origin: center;

      &:hover{
        transform: scale(1.15);
        color: $main-color;
      }
    }
  }
}
