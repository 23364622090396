$main-color:    rgba(35,74,29,1);
$main-color-2:  rgba(198,221,205,1);

$grey-light:    rgba(225,225,225,1);
$grey:          rgba(175,175,175,1);
$grey-dark:     rgba(125,125,125,1);

.loginSection{
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  overflow: hidden;

  background: -moz-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 0%, rgba(0,0,0,0.15) 100%);
  background: -webkit-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 0%,rgba(0,0,0,0.15) 100%);
  background: radial-gradient(ellipse at center,  rgba(255,255,255,1) 0%,rgba(0,0,0,0.15) 100%);

  .wrapFrom{
    position: relative;
    z-index: 1;
    width: 275px;
    height: 275px;
    padding: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    overflow: hidden;
    transform: rotateZ(45deg);
    transition: 0.15s ease all;
    box-shadow: 0 0 75px rgba(0,0,0,0.35);

    &:hover{
      box-shadow: 0 0 50px rgba(0,0,0,0.5);
    }

    .wrapFromInner{
      z-index: 1;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid $main-color;
      background: $main-color-2;
    }
    .wrapFromOuter{
      z-index: 0;
      position: absolute;
      display: block;
      width: 375px;
      height: 375px;
      background: white;
      background-position: center;
      background-size: 435px;
      transform: rotateZ(-45deg);
    }

    form{
      width: 150px;
      height: 150px;
      transform: rotateZ(-45deg);

      input.form-control{
        border-radius: 0;
        border: 0;

        &:focus{
          border-color: $main-color;
          outline: $main-color;
          box-shadow: none;
        }
        &[type="submit"]{

        }
        &[type="text"].wrongCode1{
          @media (prefers-reduced-motion: no-preference) {
            animation: Wrong-Code 1 2s linear;
          }
        }
      }
      figure{
        margin: 0;
        border-radius: 0;
        border: 0;
        background: $grey-dark;
        color: white;
        cursor: pointer;
        transition: 0.35s ease all;

        &:hover,
        &:active,
        &:focus{
          background: $main-color;
          box-shadow: 0 5px 15px rgba(0,0,0,.5);
        }
      }
    }
  }
}
.logo-react{
  position: absolute;
  fill: rgba(0,0,0,.010);
  height: 750px;
  pointer-events: none;

  @media (prefers-reduced-motion: no-preference) {
    animation: App-logo-spin infinite 200s linear;
  }
}

.input-group{
    select{
        font-size: .8rem;
        padding: 0;
        border-radius: 0;
        border: 0;

        &:nth-child(1),
        &:nth-child(2){
            border-right: 1px solid $grey-dark;
        }
    }
}


@keyframes App-logo-spin {
  from {
    transform:  rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes Wrong-Code {
  from {
    border: 1px solid red;
  }
  to {
    border: 1px solid white;
  }
}
